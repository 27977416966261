type FeatureFlagsType = {
  [key: string]: string | boolean | number | FeatureFlagsType;
};

export const featureFlagNames = {
  homepageOptimisationPhase1: "homepage_optimisation_phase_1",
};

// remember to remove from stylesheet when deleting
export const variantIds = {
  homepageOptimisationPhase1: {
    familiesList: "homepage-phase-1-job-families--list",
    resourcePromotionPanel: "homepage-phase-1-resourcePromotionPanel",
  },
};

export const setFeatureFlags = (flags: FeatureFlagsType): void =>
  sessionStorage.setItem("featureFlags", JSON.stringify(flags));

export const retrieveFeatureFlags = (): FeatureFlagsType => {
  const flags = sessionStorage.getItem("featureFlags");
  try {
    return flags ? JSON.parse(flags) : {};
  } catch (err) {
    return {};
  }
};
